import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ContentPanel from "./ContentPanel";
import Link from "@mui/material/Link";

const titleLetters = [
  {
    letter: 'D',
    color: "#DC3834",
  },
  {
    letter: 'E',
    color: "#97A4D2",
  },
  {
    letter: 'T',
    color: "#F2C57B",
  },
  {
    letter: 'A',
    color: "#198A95",
  },
  {
    letter: 'I',
    color: "#D399B0",
  },
  {
    letter: 'L',
    color: "#E16441",
  },
  {
    letter: 'S',
    color: "#567BB0",
  },
];

const DetailItem = (props) => {
  return (
    <Box>
      <Typography variant="h2" sx={{color: "#EF1C27"}} pb={{xs:0.5, md: 2}}>{props.title}</Typography>
      <Typography variant="body1">
        {props.children}
      </Typography>
    </Box>
  )
};

function Details () {
  return (
    <ContentPanel titleLetters={titleLetters}>

      <DetailItem title="Guests">
        Unfortunately, we won’t have room for additional guests at the wedding reception. It’s important to us to have a small, intimate
        celebration and as such we have selected a small venue. Guests, plus ones, and children are welcome at any of the other
        weekend events!
      </DetailItem>

      <DetailItem title="Open Bar">
        We'll have an open bar (and we've already covered gratuity) at all of our events so there's no need to remember
        how to obtain cash - phew!
      </DetailItem>

      <DetailItem title="Gifts">
        Your presence is your present! No, really, please no gifts! We’re fortunate enough to have everything we need and we believe showing up to support and celebrate with us is the best gift you can give.
      </DetailItem>

      <DetailItem title="Attire">
        We’re not picky, but you know we like to be fancy! We’ll be wearing cocktail attire, but please wear whatever
         makes you most comfortable. The ceremony will be held outdoors so please dress warmly enough and bring a
         jacket and/or umbrella if the weather looks questionable.
      </DetailItem>

      <DetailItem title="Weather">
        A number of our events will be outdoors (wedding ceremony, picnic, Saturday dinner) so please check the weather
        and dress warmly if needed. The Saturday night dinner at The Local 104 will be on a covered, heated patio so
        rain or slightly chilly weather shouldn't be an issue. It <i>might</i> be warm enough to swim at Log Boom Park
        on Saturday so consider packing a swimsuit.
      </DetailItem>

      <DetailItem title="Transportation">
        Parking downtown is annoying! We recommend public transportation, Uber, or Lyft. If you prefer to drive, the closest parking garage is below <Link href="https://goo.gl/maps/VcyDWZeEFUYXVxgg6" color="inherit">Union Square</Link>. Please don't drink and drive.
      </DetailItem>

      <DetailItem title="Children">
        The wedding reception and after party are adults-only events, but kids are welcome at the other weekend events. If you need
        help coordinating childcare, please let us know.
      </DetailItem>

      <DetailItem title="Health & Safety">
        To protect our loved ones who may be at higher risk, we are asking anyone who has any reason to believe they may have Covid (symptoms, exposure, hypochondriac, testophile, etc.) to test and/or stay home prior to the Friday night dinner. All other events will be outside or optional. Those who are traveling may test prior to departure if needed. We are happy to supply rapid tests to anyone who needs them. We will continue to monitor the Covid situation leading up to the wedding weekend and provide any final guidance modifications at least three weeks prior. As of April 2023, Covid cases are low and community transmission is well below the CDC low (green) threshold across the United States.
      </DetailItem>
    </ContentPanel>
  )
};

export default Details;
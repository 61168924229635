import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router";
import ApiClient from "./ApiClient";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import EventRsvp from "./EventRsvp";


function RsvpEvents () {

  let params = useParams();
  const pin = params.pin;
  const [rsvps, setRsvps] = useState({});
  const [guests, setGuests] = useState({});
  const [lunchPlusOnes, setLunchPlusOnes] = useState([]);
  const [dinnerPlusOnes, setDinnerPlusOnes] = useState([]);
  const [brunchPlusOnes, setBrunchPlusOnes] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    ApiClient.get(
      "invitation/" + pin + "/"
    ).then(response => {
      response.data.guests.forEach(guest => (
        ApiClient.get(
          "guest/" + guest + "/"
        ).then(response => {
          const guest = response.data;
          setGuests(currentGuests => ({...currentGuests, [guest.id]: guest}));
          ApiClient.get(
            "rsvp/" + guest.rsvp + "/"
          ).then(response => {
            const rsvp = response.data;
            setRsvps(currentRsvps => ({...currentRsvps, [rsvp.id]: rsvp}));
          })
        })
      ))
    })
  }, [pin]);

  const handleChange = (event, id, valueAttribute) => {
    let rsvp = rsvps[id];
    rsvp[valueAttribute] = event.target.value;
    setRsvps(currentRsvps => ({...currentRsvps, [id]: rsvp}))
  };

  const handleSubmit = () => {
    console.log('submit clicked');
    console.log(rsvps);
    if (
      (Object.keys(rsvps).every(id => rsvps[id].attending_picnic)) &&
      (Object.keys(rsvps).every(id => rsvps[id].attending_sat_dinner)) &&
      (Object.keys(rsvps).every(id => rsvps[id].attending_brunch))
    ) {
      const rsvpPromises = Object.keys(rsvps).map(id => (
        ApiClient.put(
          "rsvp/" + id + "/",
          rsvps[id]
        )
      ));

      const lunchPlusOnePromises = lunchPlusOnes.map(plusOne => (
        ApiClient.post(
          "plusone/",
          {
              ...plusOne,
              event: "Lunch",
              invitation: pin
          }
        )
      ));

      const dinnerPlusOnePromises = dinnerPlusOnes.map(plusOne => (
        ApiClient.post(
          "plusone/",
          {
              ...plusOne,
              event: "Sat Dinner",
              invitation: pin
          }
        )
      ));

      const brunchPlusOnePromises = brunchPlusOnes.map(plusOne => (
        ApiClient.post(
          "plusone/",
          {
              ...plusOne,
              event: "Brunch",
              invitation: pin
          }
        )
      ));

      Promise.all(
        [...rsvpPromises, ...lunchPlusOnePromises, ...dinnerPlusOnePromises, ...brunchPlusOnePromises]
      ).then(response => (
        navigate("/rsvp/success")
      ));
    }
  };

  if (rsvps) {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={{xs: 1, sm: 2}}
        px={{xs: 1, sm: 4, md: 6, lg: 10}}
      >

        <EventRsvp
          name="Picnic Lunch"
          date="Saturday, September 30th"
          time="12-2pm"
          location="Log Boom Park, Kenmore"
          guests={guests}
          rsvps={rsvps}
          valueAttribute="attending_picnic"
          onChange={handleChange}
          plusOnes={lunchPlusOnes}
          onPlusOnesChange={(value) => setLunchPlusOnes(value)}
        />

        <EventRsvp
          name="Saturday Dinner"
          date="Saturday, September 30th"
          time="6-10pm"
          location="Local 104, Lake Forest Park"
          guests={guests}
          rsvps={rsvps}
          valueAttribute="attending_sat_dinner"
          onChange={handleChange}
          plusOnes={dinnerPlusOnes}
          onPlusOnesChange={(value) => setDinnerPlusOnes(value)}
        />

        <EventRsvp
          name="Sunday Brunch"
          date="Sunday, October 1st"
          time="11-2pm"
          location="5035 NE 180th St, Lake Forest Park"
          guests={guests}
          rsvps={rsvps}
          valueAttribute="attending_brunch"
          onChange={handleChange}
          plusOnes={brunchPlusOnes}
          onPlusOnesChange={(value) => setBrunchPlusOnes(value)}
        />

        <Box display="flex" justifyContent="center" alignItems="center" pt={2}>
          <Button variant="contained" onClick={handleSubmit}>Submit</Button>
        </Box>

      </Stack>
    )
  }
};

export default RsvpEvents;
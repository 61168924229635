import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {useNavigate, useParams} from "react-router";
import ApiClient from "./ApiClient";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";


function RsvpDietaryRestrictions () {

  let params = useParams();
  const pin = params.pin;
  const [rsvps, setRsvps] = useState({});
  const [guests, setGuests] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    ApiClient.get(
      "invitation/" + pin + "/"
    ).then(response => {
      response.data.guests.forEach(guest => (
        ApiClient.get(
          "guest/" + guest + "/"
        ).then(response => {
          const guest = response.data;
          setGuests(currentGuests => ({...currentGuests, [guest.id]: guest}));
          ApiClient.get(
            "rsvp/" + guest.rsvp + "/"
          ).then(response => {
            const rsvp = response.data;
            setRsvps(currentRsvps => ({...currentRsvps, [rsvp.id]: rsvp}));
          })
        })
      ))
    })
  }, [pin]);


  const handleChange = (event, id) => {
    let rsvp = rsvps[id];
    rsvp.dietary_restrictions = event.target.value;
    setRsvps(currentRsvps => ({...currentRsvps, [id]: rsvp}))
  };

  const handleSubmit = () => {
    Promise.all(Object.keys(rsvps).map(id => (
        ApiClient.put(
          "rsvp/" + id + "/",
          rsvps[id]
        )
      ))).then(response => (
        navigate("/rsvp/" + pin + "/afterparty")
      ));
  };

  return (
    <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={{xs:1, sm: 2}}
          px={{xs: 1, sm: 4, md: 6, lg: 10}}
        >

      <Typography variant="h3" align="center">Wedding Reception</Typography>

      <Stack spacing={1} pt={2} alignItems="center">
        <Typography variant="h5" align="center" gutterBottom>Please let us know of any dietary restrictions.</Typography>

          {Object.keys(rsvps).map(id => (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="body1">{guests[rsvps[id].guest].name}</Typography>

              <TextField
                variant="standard"
                style = {{width: 250}}
                value={rsvps[id].dietary_restrictions || ""}
                onChange={event => handleChange(event, id)}
              />
            </Stack>
          ))}

        <Box display="flex" justifyContent="center" alignItems="center" pt={2}>
          <Button variant="contained" onClick={handleSubmit}>Next</Button>
        </Box>
      </Stack>

    </Stack>
  )
};

export default RsvpDietaryRestrictions;
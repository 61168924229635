import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Radio from "@mui/material/Radio/Radio";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import AddGuestDialog from "./AddGuestDialog";


const EventRsvp = (props) => {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddGuest = (name, dietaryRestrictions) => {
    props.onPlusOnesChange([...props.plusOnes, {name: name, dietary_restrictions: dietaryRestrictions, rsvp: "yes"}]);
  };

  const handlePlusOneRsvpChange = (i, rsvp) => {
    let plusOnes = props.plusOnes;
    plusOnes[i].rsvp = rsvp;
    props.onPlusOnesChange(plusOnes);
  };

  return (
    <Box pt={4}>
      <Typography variant="h3" align="center">{props.name}</Typography>

      <Stack alignItems="center">
      <Typography variant="body2">{props.date}</Typography>
      <Typography variant="body2">{props.time}</Typography>
      <Typography variant="body2">{props.location}</Typography>
      </Stack>

      <Stack spacing={1} pt={2} alignItems="center">
        <Typography variant="h5" align="center" gutterBottom>Who will be attending?</Typography>

          {Object.keys(props.rsvps).map(id => (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="body1">{props.guests[props.rsvps[id].guest].name}</Typography>

              <FormControl>
                <RadioGroup
                  row
                  value={props.rsvps[id][props.valueAttribute]}
                  onChange={(event) => props.onChange(event, id, props.valueAttribute)}
                >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel value="maybe" control={<Radio />} label="Maybe" />
                </RadioGroup>
              </FormControl>
            </Stack>
          ))}

          {props.plusOnes.map((plusOne, i) => (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="body1">{plusOne.name}</Typography>

              <FormControl>
                <RadioGroup
                  row
                  value={plusOne.rsvp}
                  onChange={(event) => handlePlusOneRsvpChange(i, event.target.value)}
                >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel value="maybe" control={<Radio />} label="Maybe" />
                </RadioGroup>
              </FormControl>
            </Stack>
          ))}

        <Button variant="text" startIcon={<AddIcon />} onClick={handleClickOpen}>Add Guests</Button>

      </Stack>

      <AddGuestDialog onAddGuest={handleAddGuest} onClose={handleClose} open={open}/>
    </Box>
  )
};

export default EventRsvp;
import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";


function ContentPanel (props) {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      mt={{xs: 0, sm: 2, md: 4, lg: 5}}
      pb={{xs: 5, sm: 3, md: 5, lg: 6}}
    >
      <Stack
        direction="row"
        spacing={{xs: .6, sm: .9, md: 1.2}}
        pb={{xs: 3, sm: 4, md: 6, lg: 10}}
      >

        {props.titleLetters.map(letter => (
          <Typography variant="h1" sx={{color: letter.color}}>{letter.letter}</Typography>
        ))}

      </Stack>
      <Container p={0}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems={props.align || "flex-start"}
          spacing={{xs:3, sm: 4, md: 5, lg: 8}}
          px={{xs: 1, sm: 4, md: 6, lg: 10}}
        >
          {props.children}
        </Stack>
      </Container>
    </Stack>
  )
};

export default ContentPanel;
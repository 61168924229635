import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";


const AddGuestDialog = (props) => {

  const [name, setName] = React.useState();
  const [dietaryRestrictions, setDietaryRestrictions] = React.useState();
  const [error, setError] = React.useState(false);

  const handleAddGuest = () => {
    if (!name) {
      setError(true);
      return;
    }
    props.onClose();
    props.onAddGuest(name, dietaryRestrictions);
    setName("");
    setDietaryRestrictions("");
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogContent>
        <DialogContentText>
          Let us know who will be joining you.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          helperText="Name"
          fullWidth
          variant="standard"
          value={name || ""}
          onChange={(event) => setName(event.target.value)}
          error={error}
        />

        <TextField
          autoFocus
          margin="dense"
          id="dietary_restrictions"
          helperText="Dietary Restrictions"
          fullWidth
          variant="standard"
          value={dietaryRestrictions || ""}
          onChange={(event) => setDietaryRestrictions(event.target.value)}
        />
      </DialogContent>
      <DialogActions p={1}>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button onClick={handleAddGuest}>Add</Button>
      </DialogActions>
    </Dialog>
)
};

export default AddGuestDialog;
import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import baker from './photos/baker.png'
import brothers from './photos/brothers.png'
import tuolumne from './photos/tuolumne.png'
import hauteroute from './photos/hauteroute.png'
import house from './photos/house.png'
import thailand from './photos/thailand.png'
import spain from './photos/spain.png'
import engagement from './photos/engagement.png'


const Item = (props) => {
  return (
    <TimelineItem>
      <TimelineOppositeContent color="text.secondary">
        {props.date}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Box pb={{xs: 1}}>
          <Box pb={{xs: 1}}>
            <Typography variant="body1" display="inline">{props.title}:&nbsp;</Typography>
            <Typography variant="body2" display="inline">
              {props.children}
            </Typography>
          </Box>
          <img src={props.image} alt={props.title} width="100%" />
        </Box>
      </TimelineContent>
    </TimelineItem>
  )
};

export default function BasicTimeline() {
  return (
    <Box mx={{xs: 0, sm: 10, md: 20, lg: 40}}>
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0,
        },
      }}
    >
      <Item date="Nov 2015" title="The adventure begins" image={baker}>
        It all started with a ski trip to Artist's Point near Mt. Baker.
      </Item>

      <Item date="Apr 2016" title="The Brothers Traverse" image={brothers}>
        Our first big climb together was a traverse of The Brothers in the Olympic Range.
      </Item>

      <Item date="Jun 2016" title="High Sierra climbing" image={tuolumne}>
        We took two climbing trips to the High Sierra in the summer of 2016, first to Tuolumne Meadows and then to The Palisades. The ticklist for these trips included: Catherdral Peak, Matthess Crest, Fairview Dome, Mt Conness, Temple Crag (3 times!), and Mt Sill.
      </Item>

      <Item date="Apr 2017" title="The Haute Route" image={hauteroute}>
        We took a trip to Europe to ski the famous Haute Route from Chamonix to Zermatt.
      </Item>

      <Item date="Sep 2017" title="Moving in" image={house}>
        We moved into our first house together in Lake City. Over the five years we lived there, we did lots of house projects with help from Jean's mom and sometimes Roman.
      </Item>

      <Item date="Mar 2019" title="Thailand & Vietnam" image={thailand}>
        We took a trip to Thailand and Vietnam to eat tasty food and try our hand at deep water soloing.
      </Item>

      <Item date="Dec 2019" title="Spain" image={spain}>
        During winter break from grad school, we drove around Spain in a camper van.
      </Item>

      <Item date="Aug 2022" title="Gunrunner Traverse & Engagement" image={engagement}>
        We finally made it out to climb the Gunrunner Traverse, a challenging and remote route in the North Cascades. At the end of the (very) long approach, Andrew revealed a wonderful surprise: truffles, Prosecco, and a ring! Then we celebrated with a delicious meal of freeze dried chicken and rice.
      </Item>

      <Item date="Sep 2023" title="The Big Day" >
        We look forward to celebrating our big day with all of you this Fall!
      </Item>


    </Timeline>
    </Box>
  );
};
import React from "react";
import Typography from "@mui/material/Typography";


function RsvpSuccess () {

  return (
    <Typography variant="h3" align="center">Thanks for your RSVP!</Typography>
  )
};

export default RsvpSuccess;
import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import ContentPanel from "./ContentPanel";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box"

const titleLetters = [
  {
    letter: 'S',
    color: "#198A95",
  },
  {
    letter: 'C',
    color: "#E16441",
  },
  {
    letter: 'H',
    color: "#D399B0",
  },
  {
    letter: 'E',
    color: "#F2C57B",
  },
  {
    letter: 'D',
    color: "#97A4D2",
  },
  {
    letter: 'U',
    color: "#79C4C8",
  },
  {
    letter: 'L',
    color: "#DC3834",
  },
  {
    letter: 'E',
    color: "#567BB0",
  },
];

const ScheduleDay = (props) => {
  return (
    <Stack alignItems="center" pb={3}>
      <Typography variant="h4" sx={{color: "#008C97", marginBottom: "0px"}} pb={0.5}>{props.date}</Typography>
      <Stack direction="column" spacing={{xs: 2, sm:3, md: 4}}>
        {props.children}
      </Stack>
    </Stack>
  )
};

const Event = (props) => {
  return (
    <Stack alignItems="center">
      <Typography variant="h3" sx={{padding: "0px", margin: "0px"}}>{props.title}</Typography>
      <Typography variant="body2" align="center">
        {props.time}&nbsp;&nbsp;|&nbsp;&nbsp;
        {props.map ? (<Link href={props.map} color="inherit">{props.location}</Link>) : props.location}
      </Typography>
      {props.children}
    </Stack>
  )
};

function Schedule () {
  return (
    <ContentPanel titleLetters={titleLetters} align="center">
      <ScheduleDay date="Friday, September 29">
        <Event title="Wedding Reception" time="5 - 9 pm" location="Cortina, Seattle" map="https://goo.gl/maps/2cCiqcepGo9K6HqL9">
          <Typography variant="body1">5:00 pm  Cocktail hour</Typography>
          <Typography variant="body1">6:00 pm  Ceremony</Typography>
          <Typography variant="body1">6:30 pm  Dinner</Typography>
        </Event>

        <Event title="After Party" time="9 pm - late" location="Ada's Technical Books, Seattle" map="https://goo.gl/maps/ph8rkqcF8jmQM4ER6">
          <Typography variant="body1" align="center">Drinking, dancing, and other shenanigans.</Typography>
          <Box pt={0.5}>
            <Typography variant="body1" align="center">
                Enter through the gate left of the front door.
            </Typography>
            <Typography variant="body1" align="center">
                <Link href="https://spotify.link/Mu5db6OycDb"
                color="inherit">Add to our playlist</Link>!
            </Typography>
          </Box>
        </Event>
      </ScheduleDay>

      <ScheduleDay date="Saturday, September 30">
        <Event title="Picnic Lunch" time="12 - 2 pm" location="Log Boom Park, Kenmore" map="https://goo.gl/maps/kf7eiB4kpcYsui7s5">
          <Box pt={0.5}>
            <Typography variant="body1" align="center">
                You'll find us at the picnic shelter <Link href="https://maps.app.goo.gl/GcpqzAg1KCqxbZT3A"  color="inherit">here</Link>.
            </Typography>
          </Box>
        </Event>

        <Event title="Bouldering" time="3 - 6 pm" location="Uplift Climbing" map="https://maps.app.goo.gl/dvhJm3YQ4kzi5w5N8">
          <Typography variant="body1" align="center">
            Join Andrew for some climbing. Heckling is encouraged.
          </Typography>
          <Typography variant="body1" align="center">
            Climbing shoe rentals are available.
          </Typography>
        </Event>

        <Event title="Beer & Board Games" time="3 - 6 pm" location="Stoup Brewing" map="https://maps.app.goo.gl/qTkGi1ERzee3ERr98">
          <Typography variant="body1" align="center">
            Join Jean for tasty local beers and board games.
          </Typography>
        </Event>

        <Event title="Dinner" time="6 - 9 pm" location="The Local 104, Lake Forest Park" map="https://goo.gl/maps/zB7mRUHNJHXpRozq5">
          <Typography variant="body1" align="center">Join us for pizza, beer, and merriment.</Typography>
          <Typography variant="body1" align="center">You'll find us out on the back patio.</Typography>
        </Event>
      </ScheduleDay>

      <ScheduleDay date="Sunday, October 1">
        <Event title="Brunch" time="9 am" location="5035 NE 180th St, Lake Forest Park" map="https://goo.gl/maps/Qe1sU7v56T2EczCQ9">
          <Typography variant="body1" align="center">Guaranteed bottomless mimosas.</Typography>
        </Event>
      </ScheduleDay>
    </ContentPanel>
  )
};

export default Schedule;
import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {useNavigate, useParams} from "react-router";
import ApiClient from "./ApiClient";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";


function RsvpGuests () {

  let params = useParams();
  const pin = params.pin;
  const [guests, setGuests] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    ApiClient.get(
      "invitation/" + pin + "/"
    ).then(response => {
      response.data.guests.forEach(guest => (
        ApiClient.get(
          "guest/" + guest + "/"
        ).then(response => {
          const guest = response.data;
          setGuests(currentGuests => ({...currentGuests, [guest.id]: guest}))
        })
      ))
    })
  }, [pin]);

  const handleChange = (event, id) => {
    let guest = guests[id];
    guest.email = event.target.value;
    setGuests(currentGuests => ({...currentGuests, [id]: guest}))
  };

  const handleBlur = (id) => {
    ApiClient.put(
      "guest/" + id + "/",
      guests[id]
    ).then(response => (
      console.log(response)
    ))
  };

  const handleSubmit = () => {
    if (Object.keys(guests).every(id => guests[id].email)) {
      navigate("/rsvp/" + pin + "/reception");
    }
  };

  return (
    <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={{xs:3, sm: 4, md: 5}}
          px={{xs: 1, sm: 4, md: 6, lg: 10}}
        >

      <Typography variant="h3" align="center">Contact Info</Typography>

      <Stack spacing={{xs:1, sm: 2, md: 3}} alignItems="center">
      <Typography variant="h5" align="center">Please let us know how we can contact you if anything changes.</Typography>

        {Object.keys(guests).map(id => (
          <Stack direction="row" alignItems="center" spacing={3}>
            <Typography variant="body1">{guests[id].name}</Typography>

            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="body2">Email: </Typography>
              <TextField
                variant="standard"
                style = {{width: 250}}
                value={guests[id].email || ""}
                onChange={event => handleChange(event, id)}
                onBlur={() => handleBlur(id)}
              />
            </Stack>
          </Stack>
        ))}

      <Box display="flex" justifyContent="center" alignItems="center" pt={2}>
        <Button variant="contained" onClick={handleSubmit}>Next</Button>
      </Box>

      </Stack>
    </Stack>
  )
}

export default RsvpGuests;
import React, {useState} from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import {useNavigate} from "react-router";


function RsvpPin () {

  const [pin, setPin] = useState('');
  const navigate = useNavigate();

  const handlePinChange = (event) => {
    setPin(event.target.value);
  };

  const handleSubmit = () => {
    if (pin) {
      navigate(pin + "/guests");
    }
  };



  return (
    <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={{xs:3, sm: 4, md: 5, lg: 8}}
          px={{xs: 1, sm: 4, md: 6, lg: 10}}
        >

      <Typography variant="h3" align="center">Let us know if you can make it!</Typography>

      <Stack spacing={{xs:1, sm: 2, md: 3}}>
      <Typography variant="body1" align="center">Enter your pin below to get started.</Typography>
      <TextField
        id="pin"
        variant="standard"
        value={pin}
        onChange={handlePinChange}
        inputProps={{style: { textAlign: 'center' }}}
      />

      <Button variant="contained" onClick={handleSubmit}>Go</Button>

      <Typography variant="body2" align="center">Your pin is included with your invitation.</Typography>
      </Stack>
    </Stack>
  )
};

export default RsvpPin;
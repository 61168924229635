import React from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Home from "./components/Home";
import Schedule from "./components/Schedule";
import Details from "./components/Details";
import Rsvp from "./components/Rsvp";
import Layout from "./components/Layout";
import {WeddingTheme} from "./components/WeddingTheme"
import './App.css'
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import RsvpSuccess from "./components/RsvpSuccess";
import RsvpGuests from "./components/RsvpGuests";
import RsvpReception from "./components/RsvpReception";
import RsvpEvents from "./components/RsvpEvents";
import RsvpPin from "./components/RsvpPin";
import RsvpDietaryRestrictions from "./components/RsvpDietaryRestrictions";
import RsvpAfterParty from "./components/RsvpAfterParty";

function App() {
  return (

    <ThemeProvider theme={WeddingTheme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout/>}>
            <Route index element={<Home />} />
            <Route path="schedule" element={<Schedule />} />
            <Route path="details" element={<Details />} />
            <Route path="rsvp/" element={<Rsvp />} >
              <Route path="" element={<RsvpPin />} />
              <Route path=":pin/guests" element={<RsvpGuests />} />
              <Route path=":pin/reception" element={<RsvpReception />} />
              <Route path=":pin/dietaryrestrictions" element={<RsvpDietaryRestrictions/>} />
              <Route path=":pin/afterparty" element={<RsvpAfterParty/>} />
              <Route path=":pin/events" element={<RsvpEvents />} />
              <Route path="success" element={<RsvpSuccess />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;

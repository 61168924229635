import { createTheme } from "@mui/material/styles";


const theme = createTheme(
  {
    palette: {
      mode: 'light',
      primary: {
        main: '#3f51b5',
      },
      secondary: {
        main: '#f50057',
      },
    },
    typography: {
      h1: {
        fontWeight: 400,
        fontFamily: 'Merriweather',
        fontSize: '6rem',
        '@media (min-width:840px) and (max-width:950px)': {
          fontSize: '5rem',
        },
        '@media (min-width:700px) and (max-width:840px)': {
          fontSize: '4rem',
        },
        '@media (min-width:600px) and (max-width:700px)': {
          fontSize: '3rem',
        },
        '@media (min-width:500px) and (max-width:600px)': {
          fontSize: '2.5rem',
        },
        '@media (max-width:500px)': {
          fontSize: '3rem',
        },


      },
      h2: {
        fontFamily: 'Merriweather',
        fontSize: '2rem',
        '@media (min-width:840px) and (max-width:950px)': {
          fontSize: '1.9rem',
        },
        '@media (min-width:700px) and (max-width:840px)': {
          fontSize: '1.8rem',
        },
        '@media (min-width:600px) and (max-width:700px)': {
          fontSize: '1.5rem',
        },
        '@media (max-width:600px)': {
          fontSize: '1.2rem',
        },
      },
      h3: {
        fontFamily: 'Libre Franklin',
        fontSize: '2.2rem',
        '@media (min-width:840px) and (max-width:950px)': {
          fontSize: '2.1rem',
        },
        '@media (min-width:700px) and (max-width:840px)': {
          fontSize: '2rem',
        },
        '@media (min-width:600px) and (max-width:700px)': {
          fontSize: '1.8rem',
        },
        '@media (max-width:600px)': {
          fontSize: '1.6rem',
        },
      },
      h4: {
        fontFamily: 'Merriweather',
        fontSize: '1.2rem',
        '@media (max-width:840px)': {
          fontSize: '1rem',
        },
      },
      h5: {
        fontFamily: 'Libre Franklin',
        fontSize: '1.2rem',
        '@media (min-width:840px) and (max-width:950px)': {
          fontSize: '1.1rem',
        },
        '@media (min-width:700px) and (max-width:840px)': {
          fontSize: '1rem',
        },
        '@media (min-width:600px) and (max-width:700px)': {
          fontSize: '.9rem',
        },
        '@media (max-width:600px)': {
          fontSize: '.8rem',
        },
      },
      body1: {
        fontFamily: 'Libre Franklin',
        fontSize: '1rem',
      },
      body2: {
        fontFamily: 'Libre Franklin',
        fontSize: '1rem',
        color: "#777777"
      },
      button: {
        fontFamily: 'Libre Franklin'
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 500,
        md: 800,
        lg: 950,
        xl: 1100,
      }
    }
  }
);


export const WeddingTheme = theme;
import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {useNavigate, useParams} from "react-router";
import ApiClient from "./ApiClient";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";


function RsvpAfterParty () {

  let params = useParams();
  const pin = params.pin;
  const [rsvps, setRsvps] = useState({});
  const [guests, setGuests] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    ApiClient.get(
      "invitation/" + pin + "/"
    ).then(response => {
      response.data.guests.forEach(guest => (
        ApiClient.get(
          "guest/" + guest + "/"
        ).then(response => {
          const guest = response.data;
          setGuests(currentGuests => ({...currentGuests, [guest.id]: guest}));
          ApiClient.get(
            "rsvp/" + guest.rsvp + "/"
          ).then(response => {
            const rsvp = response.data;
            setRsvps(currentRsvps => ({...currentRsvps, [rsvp.id]: rsvp}));
          })
        })
      ))
    })
  }, [pin]);

  const handleChange = (event, id) => {
    let rsvp = rsvps[id];
    rsvp.attending_after_party = event.target.value;
    setRsvps(currentRsvps => ({...currentRsvps, [id]: rsvp}))
  };

  const handleSubmit = () => {
    if (Object.keys(rsvps).every(id => rsvps[id].attending_after_party)) {
      Promise.all(Object.keys(rsvps).map(id => (
        ApiClient.put(
          "rsvp/" + id + "/",
          rsvps[id]
        )
      ))).then(response => (
        navigate("/rsvp/" + pin + "/events")
      ));
    }
  };
  return (
    <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={{xs:1, sm: 2}}
          px={{xs: 1, sm: 4, md: 6, lg: 10}}
        >

      <Typography variant="h3" align="center">After Party</Typography>

      <Stack alignItems="center">
      <Typography variant="body2">Friday, September 29th</Typography>
      <Typography variant="body2">9pm</Typography>
      <Typography variant="body2">Ada's Technical Books, Capitol Hill</Typography>
      </Stack>

      <Stack spacing={1} pt={2} alignItems="center">
        <Typography variant="h5" align="center" gutterBottom>Who will be attending the after party?</Typography>

          {Object.keys(rsvps).map(id => (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="body1">{guests[rsvps[id].guest].name}</Typography>

              <FormControl>
                <RadioGroup
                  row
                  value={rsvps[id].attending_after_party}
                  onChange={(event) => handleChange(event, id)}
                >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel value="maybe" control={<Radio />} label="Maybe" />
                </RadioGroup>
              </FormControl>
            </Stack>
          ))}

        <Box display="flex" justifyContent="center" alignItems="center" pt={2}>
          <Button variant="contained" onClick={handleSubmit}>Next</Button>
        </Box>

      </Stack>
    </Stack>
  )
};

export default RsvpAfterParty;
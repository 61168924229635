import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from "@mui/material/MenuItem";


const pages = ['home', 'schedule', 'details', 'rsvp'];

export default function NavBar() {

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" variant="dense" color="transparent" p={0} m={0}>
        <Toolbar variant="dense" disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}>
            <IconButton
              size="large"
              aria-label="main menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
              disableAutoFocusItem={true}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Button
                    href={"/" + (page === 'home'? "" : page.split(/\s+/).join('').toLowerCase())}
                    onClick={handleCloseNavMenu}
                    sx={{ my: "5px", color: 'black', display: 'block', mx: '5px', p: '0px'}}
                    variant="link"
                    disableRipple>
                    {page}
                  </Button>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box display="flex" sx={{ flexGrow: 1, justifyContent: "flex-end", alignItems:"flex-end", marginLeft:"auto", display: { xs: 'none', sm: 'flex' } }}>
            {pages.map((page) => (
              <Box display={page === 'home' ? 'flex' : 'normal'} flexGrow={page === 'home' ? 1 : 0} mx={1}>
                <Button
                  href={"/" + (page === 'home'? "" : page.split(/\s+/).join('').toLowerCase())}
                  onClick={handleCloseNavMenu}
                  sx={{ mt: "10px", color: 'black', display: 'block', mx:2.5, p: '0px', minWidth:0}}
                  variant="link"
                  disableRipple
                >
                  {page === 'home' ? 'J + A' : page}
                </Button>
              </Box>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
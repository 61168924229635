import React from "react";
import Typography from "@mui/material/Typography";
import MtVelan from './photos/MtVelan.jpg'
import MtVelanSmall from './photos/MtVelanSmall.jpeg'
import Stack from "@mui/material/Stack";
import Hidden from "@mui/material/Hidden";
import RelationshipTimeline from "./RelationshipTimeline";

const jeanLetters = [
  {
    letter: 'J',
    color: "#EF1C27",
  },
  {
    letter: 'E',
    color: "#FAC46E",
  },
  {
    letter: 'A',
    color: "#94A3D6",
  },
  {
    letter: 'N',
    color: "#008C97",
  },
];

const andrewLetters = [
  {
    letter: 'A',
    color: "#4C7BB5",
  },
  {
    letter: 'N',
    color: "#DE95B1",
  },
  {
    letter: 'D',
    color: "#60A563",
  },
  {
    letter: 'R',
    color: "#F25A33",
  },
  {
    letter: 'E',
    color: "#5FC6C9",
  },
  {
    letter: 'W',
    color: "#7677C3",
  },
];


function Home () {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{mt: {xs: 0, sm: 2, md: 4, lg: 5}}}
      pb={{xs: 5, sm: 3, md: 5, lg: 6}}
    >

      <Stack
        justifyContent="center"
        alignItems="center"
        direction={{ xs: 'column', sm: 'row' }}
      >

        <Stack
          direction="row"
          spacing={{xs: .6, sm: .9, md: 1.2}}
        >
          {jeanLetters.map(letter => (
            <Typography variant="h1" sx={{color: letter.color}}>{letter.letter}</Typography>
          ))}
        </Stack>

        <Typography variant="h2" sx={{color: "#000000"}} mx={2}>+</Typography>

        <Stack
          direction="row"
          spacing={{xs: .6, sm: .8, md: 1.2}}
        >
          {andrewLetters.map(letter => (
            <Typography variant="h1" sx={{color: letter.color}}>{letter.letter}</Typography>
          ))}
        </Stack>
      </Stack>

      <Stack pb={{xs: 0, sm: 1, md: 5, lg: 14}} pt={1} alignItems="center">
        <Typography variant="h5" pb={1.5} >SEPT 29, 2023 ⬩ SEATTLE, WA</Typography>
      </Stack>

      <Hidden smDown>
        <img src={MtVelan} alt="" width="100%vw" />
      </Hidden>
      <Hidden smUp>
        <img src={MtVelanSmall} alt="" width="100%vw" />
      </Hidden>

      <Typography variant="h3" pt={4}>The story so far...</Typography>
      <Typography variant="body1" px={{xs: 2, sm: 4, md: 6, lg: 10}} pt={{xs: 1, sm: 2}} align="center">
        We first met about eight (!!!) years ago and after many climbing trips, house projects, late nights in grad school, and other shenanigans, we're getting married! Keep scrolling for a few highlights from the past eight years.
      </Typography>

      <RelationshipTimeline />



    </Stack>
  )
};

export default Home;
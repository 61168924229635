import React from "react";
import ContentPanel from "./ContentPanel";
import {Outlet} from "react-router";

const titleLetters = [
  {
    letter: 'R',
    color: "#567BB0",
  },
  {
    letter: 'S',
    color: "#F2C57B",
  },
  {
    letter: 'V',
    color: "#E16441",
  },
  {
    letter: 'P',
    color: "#198A95",
  },
];

function Rsvp () {

  return (
    <ContentPanel titleLetters={titleLetters} align="center">
      <Outlet />
    </ContentPanel>
  )
};

export default Rsvp;
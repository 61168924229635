import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {useNavigate, useParams} from "react-router";
import ApiClient from "./ApiClient";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";


function RsvpReception () {

  let params = useParams();
  const pin = params.pin;
  const [rsvps, setRsvps] = useState({});
  const [guests, setGuests] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    ApiClient.get(
      "invitation/" + pin + "/"
    ).then(response => {
      response.data.guests.forEach(guest => (
        ApiClient.get(
          "guest/" + guest + "/"
        ).then(response => {
          const guest = response.data;
          setRsvps(currentRsvps => ({...currentRsvps, [guest.id]: {guest: guest.id}}));
          setGuests(currentGuests => ({...currentGuests, [guest.id]: guest}));
        })
      ))
    })
  }, [pin]);

  const handleChange = (event, id) => {
    let rsvp = rsvps[id];
    rsvp.attending_reception = event.target.value;
    setRsvps(currentRsvps => ({...currentRsvps, [id]: rsvp}))
  };

  const handleSubmit = () => {
    if (Object.keys(rsvps).every(id => rsvps[id].attending_reception)) {
      Promise.all(Object.keys(rsvps).map(id => (
        ApiClient.post(
          "rsvp/",
          rsvps[id]
        )
      ))).then(response => (
        navigate("/rsvp/" + pin + "/dietaryrestrictions")
      ));
    }
  };

  return (
    <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={{xs:1, sm: 2}}
          px={{xs: 1, sm: 4, md: 6, lg: 10}}
        >

      <Typography variant="h3" align="center">Wedding Reception</Typography>

      <Stack alignItems="center">
      <Typography variant="body2">Friday, September 29th</Typography>
      <Typography variant="body2">5-9pm</Typography>
      <Typography variant="body2">Cortina Seattle</Typography>
      </Stack>

      <Stack spacing={1} pt={2} alignItems="center">
        <Typography variant="h5" align="center" gutterBottom>Who will be attending the reception?</Typography>

          {Object.keys(rsvps).map(id => (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="body1">{guests[id].name}</Typography>

              <FormControl>
                <RadioGroup
                  row
                  value={rsvps[id].attending_reception}
                  onChange={(event) => handleChange(event, id)}
                >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Stack>
          ))}

        <Box display="flex" justifyContent="center" alignItems="center" pt={2}>
          <Button variant="contained" onClick={handleSubmit}>Next</Button>
        </Box>

      </Stack>
    </Stack>
  )
};

export default RsvpReception;